import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { getAllInfu } from "../../actions/users";
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Link, useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { toastAlert } from "../../lib/toastAlert";
import CardHeader from "components/Card/CardHeader.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Card from "components/Card/Card.js";
import { DataGrid, RowsProp, ColDef } from "@material-ui/data-grid";
import CardBody from "components/Card/CardBody.js";

// function createData(FirstName, lastName, Email, phoneNo, accountType) {
//   return { FirstName, lastName, Email, phoneNo, accountType };
// }

const rows = [
  // getData('')
  //   createData('firstName', 'lastName', 'Email', 'phoneNo', 'accountType')
  //   createData('Donut', 452, 25.0, 51, 4.9),
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "5px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
}));

const customStyle = makeStyles(customInputStyle);

const EnhancedTable = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const columns = [
    { field: "Address", width: 400, headerName: "Address" },
    { field: "name", width: 200, headerName: "Name" },
    {
      field: "mobile",
      width: 200,
      headerName: "Phone No.",
    },

    {
      field: "startMonthYear",
      width: 200,
      headerName: "Contract",
      renderCell: (params) => {
        return (
          <div>
            {params.row?.startMonthYear + " To " + params.row?.endMonthYear}
          </div>
        );
      },
    },
    // { field: "Date", width: 200, headerName: "Date" },
    {
      field: "addpr",
      width: 200,
      headerName: "Add Promotors",
      renderCell: (params) => {
        return (
          <div>
            <Link
              to={"/addpromotor/" + params.row._id}
              className={classes.btnPrimary}
            >
              AddPromotors
            </Link>
          </div>
        );
      },
    },
    {
      field: "list",
      width: 200,
      headerName: "Promotors",

      renderCell: (params) => {
        return (
          <div>
            <Link
              to={"/promotors/" + params.row._id}
              className={classes.btnPrimary}
            >
              Promotors
            </Link>
          </div>
        );
      },
    },
    {
      field: "target",
      width: 200,
      headerName: "Target",

      renderCell: (params) => {
        return (
          <div>
            <Link
              to={"/influencertarget/" + params.row._id}
              className={classes.btnPrimary}
            >
              View Target
            </Link>
          </div>
        );
      },
    },
    {
      field: "kycstatus",
      width: 200,
      headerName: "KYC Status",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      getAll();
    }, 500);
  }, []);
  const getAll = async (search = {}) => {
    let filterData = search;
    if (filterData.search == "") {
      filterData = {};
    }

    var res = await getAllInfu(filterData);
    console.log(
      "result=================dddddddddddddddddddddddddddddddddddd==",
      res
    );
    if (res && res.result) {
      console.log("result===================", res.result.data);

      let respData = res.result.data.map((item, key) => {
        console.log(item, "itemmmmmmmmmmmmmmm");
        return {
          id: key,
          ...item,
        };
      });
      console.log(respData, "---respData");

      setData(respData);
      //console.log("result of");
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Influencers List</h4>
              <Link to={"/addinfluencer"} className={classes.btnPrimary}>
                Add Influencer
              </Link>
            </div>
          </CardHeader>

          <CardBody>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={10}
                pagination
                rows={data}
                columns={columns}
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
};

export default EnhancedTable;
