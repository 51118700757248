import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toastAlert } from "../../lib/toastAlert";
import ConnectWallet from "./connectwallet";
//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { providerOptions } from "../../actions/providerOptions";
import { addwhitelist, updatepricecnv } from "../../actions/users";
import UPRO_ABI from "./UPRO_ABI.json";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  address: "",
};

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});

  const dispatch = useDispatch();
  const [walletconnect, setwalletconnect] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const { userId } = useParams();
  const [Loader, setLoader] = useState(false);
  const [tokenprice, settokenprice] = useState("");
  const [minstake, setminstake] = useState("");

  const web3Modal = new Web3Modal({
    providerOptions, // required,
    cacheProvider: true, // optional
  });

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    settokenprice(value);
    // console.log(formValue);
  };

  const onChangeminstake = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    setminstake(value);
    // console.log(formValue);
  };

  const { address } = formValue;

  useEffect(() => {}, [walletconnect]);

  useEffect(() => {
    getTokenprice();
    getMinStake();
  }, []);
  const getTokenprice = async () => {
    try {
      var web3 = new Web3("https://mainnet-rpc.ultraproscan.io/");
      let contractAddr = "0x5F581cbbE5D96B19A19D3c281D541Fe94cd255FD";
      var Contract = new web3.eth.Contract(UPRO_ABI, contractAddr);
      var tokenprice = await Contract.methods.tokenPerUsd().call();
      settokenprice(tokenprice / 100000000);
    } catch (err) {}
  };

  const getMinStake = async () => {
    try {
      var web3 = new Web3("https://mainnet-rpc.ultraproscan.io/");
      let contractAddr = "0x5F581cbbE5D96B19A19D3c281D541Fe94cd255FD";
      var Contract = new web3.eth.Contract(UPRO_ABI, contractAddr);
      var minamt = await Contract.methods.minInvestment().call();
      setminstake(minamt / 10 ** 18);
    } catch (err) {}
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure want to update Token Price?") == true) {
      setLoader(true);
      // console.log(formValue);

      if (isEmpty(tokenprice)) {
        toastAlert("error", "Please Enter Price", "address");
        setLoader(false);

        return;
      }

      if (isEmpty(walletconnect)) {
        toastAlert("error", "Please Connect Metamask", "address");
        setLoader(false);

        return;
      }

      if (web3Modal.cachedProvider) {
        var provider = await web3Modal.connect();
        var web3333 = new Web3(provider);
        let getId = await web3333.eth.net.getId();
        console.log(getId, "getIdgetIdgetIdgetIdgetIdgetIdgetIdgetIdgetId");
        var contractAddr = "0x5F581cbbE5D96B19A19D3c281D541Fe94cd255FD";

        if (provider && provider?.on) {
          try {
            let result = await web3333.eth.getAccounts();
            var metamaskAddress = result[0];
            console.log(metamaskAddress, "metamaskAddress", contractAddr);
            var Contract = new web3333.eth.Contract(UPRO_ABI, contractAddr);
            var owner_address = await Contract.methods.OwnerWallet().call();
            console.log(owner_address, "owner_address");

            if (owner_address != metamaskAddress) {
              toastAlert(
                "error",
                "Please Connect Correct Admin wallet",
                "address"
              );
              setLoader(false);

              return;
            }
          } catch (err) {
            console.log(err);
            toastAlert("error", "Something went wrong", "address");
            setLoader(false);

            return;
          }
        }
      }

      // console.log(reqData);

      try {
        let price = tokenprice * 100000000;
        price = await convert(price);
        let pricestr = price.toString();
        let priceArr = pricestr.split(".");
        if (priceArr.length == 2) {
          price = priceArr[0];
        }
        console.log(price, "---198");

        var addwlist = await Contract.methods
          .setTokenPrice(price)
          .send({ from: metamaskAddress });
        console.log(addwlist, "---addwlist");

        let reqData = { price: tokenprice };
        await updatepricecnv(reqData);

        toastAlert("success", "Token Price Updated successfully", "address");
        setLoader(false);

        history.push("/priceupdate");
      } catch (err) {
        console.log(err, "-----163");
        toastAlert("error", "Error on contract", "address");
        setLoader(false);
      }
    }
  };

  const handleFormSubmitMinstake = async (e) => {
    e.preventDefault();
    if (
      window.confirm("Are you sure want to update Minimum Stake Amount?") ==
      true
    ) {
      setLoader(true);
      // console.log(formValue);

      if (isEmpty(tokenprice)) {
        toastAlert("error", "Please Enter Min Stake Amount", "address");
        setLoader(false);

        return;
      }

      if (isEmpty(walletconnect)) {
        toastAlert("error", "Please Connect Metamask", "address");
        setLoader(false);

        return;
      }

      if (web3Modal.cachedProvider) {
        var provider = await web3Modal.connect();
        var web3333 = new Web3(provider);
        let getId = await web3333.eth.net.getId();
        console.log(getId, "getIdgetIdgetIdgetIdgetIdgetIdgetIdgetIdgetId");
        var contractAddr = "0x5F581cbbE5D96B19A19D3c281D541Fe94cd255FD";

        if (provider && provider?.on) {
          try {
            let result = await web3333.eth.getAccounts();
            var metamaskAddress = result[0];
            console.log(metamaskAddress, "metamaskAddress", contractAddr);
            var Contract = new web3333.eth.Contract(UPRO_ABI, contractAddr);
            var owner_address = await Contract.methods.OwnerWallet().call();
            console.log(owner_address, "owner_address");

            if (owner_address != metamaskAddress) {
              toastAlert(
                "error",
                "Please Connect Correct Admin wallet",
                "address"
              );
              setLoader(false);

              return;
            }
          } catch (err) {
            console.log(err);
            toastAlert("error", "Something went wrong", "address");
            setLoader(false);

            return;
          }
        }
      }

      // console.log(reqData);

      try {
        let minInvestment = minstake * 10 ** 18;
        minInvestment = await convert(minInvestment);
        let minInvestmentstr = minInvestment.toString();
        let minInvestmentArr = minInvestmentstr.split(".");
        if (minInvestmentArr.length == 2) {
          minInvestment = minInvestmentArr[0];
        }

        var addwlist = await Contract.methods
          .setminInvestment(minInvestment)
          .send({ from: metamaskAddress });
        console.log(addwlist, "264---addwlist");

        toastAlert(
          "success",
          "Minimum Stake Amount Updated successfully",
          "address"
        );
        setLoader(false);

        history.push("/priceupdate");
      } catch (err) {
        console.log(err, "-----163");
        toastAlert("error", "Error on contract", "address");
        setLoader(false);
      }
    }
  };

  let formdata = {};

  const convert = async (n) => {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign +
            "0." +
            "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
            lead +
            decimal
        : sign +
            lead +
            (+pow >= decimal.length
              ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
              : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
      return 0;
    }
  };

  return (
    <div>
      <ConnectWallet setwalletconnect={setwalletconnect} />
      {console.log(walletconnect, "------setwalletconnect")}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Token Price Update</h4>
                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      onChange={onChange}
                      id="address"
                      labelText="Price"
                      value={address ? address : tokenprice}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {toched.address && validateError.address && (
                      <p className="error-message">{validateError.address}</p>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit" disabled={Loader}>
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmitMinstake}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Minimum Stake Amount Update
                </h4>
                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      onChange={onChangeminstake}
                      id="stakemin"
                      labelText="Minimum Stake Amount"
                      value={address ? address : minstake}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {toched.address && validateError.address && (
                      <p className="error-message">{validateError.address}</p>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit" disabled={Loader}>
                  Update Min Stake
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
