import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";

// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, RowsProp, ColDef } from "@material-ui/data-grid";
import { Paper } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// import action
import { getAllstakemembers, sendistrimember } from "../../actions/videos";

// import
import { toastAlert } from "../../lib/toastAlert";
import { useParams } from "react-router-dom";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};
const useStyles = makeStyles(styles);

const UserLevels = (props) => {
  const classes = useStyles();
  const params = useParams();
  // state
  const [data, setData] = useState([]);
  const [CurrentDate, setCurrentDate] = useState();

  const columns = [
    { field: "Address", headerName: "Address", width: 400 },
    { field: "totalCount", headerName: "Total Count", width: 200 },
    { field: "currentCount", headerName: "Current Count", width: 200 },
    {
      field: "Amount",
      headerName: "Amount",
      width: 200,
      renderCell: (params) => {
        return <Fragment>{params.row?.Reward?.Amount}</Fragment>;
      },
    },

    {
      field: "distributionstatus",
      headerName: "Distribution Status",
      width: 200,
      renderCell: (params) => {
        return <Fragment>{params.row?.Reward?.distributionstatus}</Fragment>;
      },
    },

    {
      field: "rewarddate",
      headerName: "Date",
      width: 200,
      renderCell: (params) => {
        return <Fragment>{params.row?.Reward?.rewarddate}</Fragment>;
      },
    },

    {
      field: "distributionstatusbtn",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        let Cd = new Date(CurrentDate).toISOString().split("T")[0];
        let Dd = new Date(params.row?.Reward?.rewarddate)
          .toISOString()
          .split("T")[0];

        //  console.log(Cd, Dd, "-----110");
        return (
          <Fragment>
            {params.row?.Reward?.distributionstatus == "pending" &&
              Cd == Dd && (
                <Link
                  onClick={(e) =>
                    sendDistribute(params.row?._id, params.row?.Reward._id)
                  }
                  className={classes.btnPrimary}
                >
                  Send Distribution
                </Link>
              )}
          </Fragment>
        );
      },
    },
  ];

  // function
  const fetchhistory = async () => {
    try {
      const { status, loading, error, result, currentDate } =
        await getAllstakemembers();
      if (status == "success") {
        console.log(result, "----099999");
        let respData = result.map((item, key) => {
          // console.log(item, 'itemmmmmmmmmmmmmmm')
          return {
            id: key,
            ...item,
          };
        });
        // console.log(respData, 'resssssssssss')
        setData(respData);
        console.log(currentDate, "---respData.currentdate");
        setCurrentDate(currentDate);
      } else {
      }
    } catch (err) {
      console.log(err, "errror");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchhistory();
    }, 500);
  }, []);

  const sendDistribute = async (mainid, id) => {
    if (window.confirm("Are you sure want to update this record?") == true) {
      var res = await sendistrimember({ mainid: mainid, id: id });
      // console.log(res, "----747474");
      toastAlert("success", res.message, "address");
      fetchhistory();
      // console.log(id, "idddd------", mainid, "-mainid----");
    }
  };

  // console.log(data, 'datadata')

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Staked Direct Members</h4>
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={10}
                pagination
                rows={data}
                columns={columns}
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
};

export default UserLevels;
