import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { getAllpro } from "../../actions/users";
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Link, useHistory, useParams } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { toastAlert } from "../../lib/toastAlert";
import CardHeader from "components/Card/CardHeader.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import { DataGrid } from "@material-ui/data-grid";

// function createData(FirstName, lastName, Email, phoneNo, accountType) {
//   return { FirstName, lastName, Email, phoneNo, accountType };
// }

const rows = [
  // getData('')
  //   createData('firstName', 'lastName', 'Email', 'phoneNo', 'accountType')
  //   createData('Donut', 452, 25.0, 51, 4.9),
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "5px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
}));

const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
  const classes = useStyles();
  const customStyles = customStyle();
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userDetails, setUser] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const { id } = useParams();
  const [alldata, setallData] = useState();

  const columns = [
    { field: "Address", width: 400, headerName: "Address" },
    { field: "name", width: 200, headerName: "Name" },
    { field: "mobile", width: 200, headerName: "Phone No." },
    {
      field: "Contract",
      width: 200,
      headerName: "Contract",
      renderCell: (params) => {
        return (
          <div>
            {params.row?.startMonthYear + " To " + params.row?.endMonthYear}
          </div>
        );
      },
    },
    { field: "createdAt", width: 200, headerName: "Date" },
    {
      field: "Target",
      width: 200,
      headerName: "Target",
      renderCell: (params) => {
        return (
          <div>
            <Link
              to={"/promotorstarget/" + params.row._id + "/" + id}
              className={classes.btnPrimary}
            >
              ViewTarget
            </Link>
          </div>
        );
      },
    },

    {
      field: "Members",
      width: 200,
      headerName: "Members",
      renderCell: (params) => {
        return (
          <div>
            <Link
              to={"/promotorsreferrals/" + params.row._id + "/" + id}
              className={classes.btnPrimary}
            >
              Members
            </Link>
          </div>
        );
      },
    },

    { field: "kycstatus", width: 200, headerName: "KYC Status" },
  ];

  useEffect(() => {
    setTimeout(() => {
      getAll();
    }, 500);
  }, [id]);
  const getAll = async (search = {}) => {
    let filterData = search;
    if (filterData.search == "") {
      filterData = {};
    }

    var res = await getAllpro({ infId: id });
    console.log(
      "result=================dddddddddddddddddddddddddddddddddddd==",
      res
    );
    if (res && res.result) {
      let respData = res.result.data.map((item, key) => {
        console.log(item, "itemmmmmmmmmmmmmmm");
        return {
          id: key,
          ...item,
        };
      });
      console.log(respData, "---respData");

      setData(respData);
      setallData(res?.result?.alldata);
      console.log("result of");
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ padding: "10px" }} className={classes.flexHeader}>
          <Link to={"/influencer"} className={classes.btnPrimary}>
            Back
          </Link>
        </div>

        <div>
          <span style={{ color: "green" }}>
            {" "}
            &nbsp;&nbsp; Influencer Address : {alldata?.Address}
          </span>
        </div>

        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Promotor List</h4>
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={10}
                pagination
                rows={data}
                columns={columns}
              />
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );

  // return (
  //   <div className={classes.root}>
  //     <Paper className={classes.paper}>
  //       <Toolbar
  //         className={clsx(classesSearch.root, {
  //           [classesSearch.highlight]: selected.length > 0,
  //         })}
  //       >
  //         <div className={classes.flexHeader}>
  //           <Link to={"/influencer"} className={classes.btnPrimary}>
  //             Back
  //           </Link>
  //         </div>
  //       </Toolbar>

  //       <div>
  //         <span style={{ color: "green" }}>
  //           {" "}
  //           &nbsp;&nbsp; Influencer Address : {alldata?.Address}
  //         </span>
  //       </div>

  //       <Card>
  //         <CardHeader color="primary">
  //           <div className={classes.flexHeader}>
  //             <h4 className={classes.cardTitleWhite}>Promotors List</h4>
  //           </div>
  //         </CardHeader>
  //         <br />
  //         <TableContainer>
  //           <Table
  //             className={classes.table}
  //             aria-labelledby="tableTitle"
  //             // size={dense ? 'small' : 'medium'}
  //             aria-label="enhanced table"
  //           >
  //             <EnhancedTableHead
  //               classes={classes}
  //               numSelected={selected.length}
  //               //   order={order}
  //               orderBy={orderBy}
  //               onSelectAllClick={handleSelectAllClick}
  //               onRequestSort={handleRequestSort}
  //               rowCount={rows.length}
  //             />
  //             <TableBody>
  //               {userDetails &&
  //                 userDetails
  //                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  //                   .map((row, index) => {
  //                     // const labelId = `enhanced-table-checkbox-${index}`;
  //                     return (
  //                       <TableRow>
  //                         <TableCell align="center">{row.Address}</TableCell>
  //                         <TableCell align="center">{row.name}</TableCell>
  //                         <TableCell align="center">{row.mobile}</TableCell>

  //                         <TableCell align="center">
  //                           {row.startMonthYear} To {row.endMonthYear}
  //                         </TableCell>
  //                         <TableCell align="center">{row.createdAt}</TableCell>

  //                         <TableCell>
  //                           <Link
  //                             to={"/promotorstarget/" + row._id + "/" + id}
  //                             className={classes.btnPrimary}
  //                           >
  //                             ViewTarget
  //                           </Link>
  //                         </TableCell>

  //                         <TableCell>
  //                           <Link
  //                             to={"/promotorsreferrals/" + row._id + "/" + id}
  //                             className={classes.btnPrimary}
  //                           >
  //                             Members
  //                           </Link>
  //                         </TableCell>

  //                         <TableCell align="center">{row.kycstatus}</TableCell>
  //                       </TableRow>
  //                     );
  //                   })}
  //             </TableBody>
  //           </Table>
  //         </TableContainer>
  //         <TablePagination
  //           rowsPerPageOptions={[5, 10, 25]}
  //           component="div"
  //           count={userDetails && userDetails.length}
  //           rowsPerPage={rowsPerPage}
  //           page={page}
  //           onChangePage={handleChangePage}
  //           onChangeRowsPerPage={handleChangeRowsPerPage}
  //         />
  //       </Card>
  //     </Paper>
  //   </div>
  // );
}
